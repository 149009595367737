/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

html {
  overflow: hidden;
  overscroll-behavior: none;
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.overlay {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
}

.pin-spacer {
  /* background: white; */
  background: transparent;
}

/* html, body { height: auto; } */
.panel {
  padding: 0.5rem 1.5rem 1rem 1.5rem !important;
}

.panel-1 {
  background: linear-gradient(
    234.48deg,
    #ffdfe4 0%,
    rgba(255, 255, 255, 1) 100%
  );
  backdrop-filter: blur(10px);
}

.panel-2 {
  background: linear-gradient(
    124.86deg,
    #ffe192 0%,
    rgba(255, 249, 234, 1) 100%
  );
  backdrop-filter: blur(10px);
}

.panel-3 {
  background: linear-gradient(
    248.77deg,
    #d2eafc 8.47%,
    rgba(250, 253, 255, 1) 93.16%
  );
  backdrop-filter: blur(10px);
}

.panel-4 {
  background: linear-gradient(
    248.77deg,
    rgba(251, 253, 255, 1) 8.47%,
    #f1ffd0 93.16%
  );
  backdrop-filter: blur(10px);
}

.panel-5 {
  background: linear-gradient(
    284.42deg,
    rgba(251, 253, 255, 1) 7.66%,
    #d2d6fd 85.17%
  );
  backdrop-filter: blur(10px);
}

.panel-6 {
  background: linear-gradient(
    254.84deg,
    rgba(255, 255, 255, 1) 5.71%,
    #e8e8e8 88.53%
  );
  backdrop-filter: blur(10px);
}

.panel-7 {
  background: linear-gradient(
    241.09deg,
    #c8c8c8 8.81%,
    rgba(232, 232, 232, 1) 98.94%
  );
  backdrop-filter: blur(10px);
}

/* ::-webkit-scrollbar {
    width: 0px;
    height: 0;
  }
  

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    width: 0;
    height: 0;
  }
   

  ::-webkit-scrollbar-thumb {
    background: red; 
    border-radius: 10px;
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
    width: 0;
    height: 0;
  } */

.shimmer {
  font-family: "Lato";
  font-weight: 300;
  font-size: 3em;
  margin: 0 auto;
  padding: 0 140px 0 0;
  display: inline;
  margin-bottom: 0;
}
.shine-text {
  display: flex;
  justify-content: center;
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  /* background: -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background: -moz-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff)); */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background-size: 125px 100%;
  background-clip: text;
  animation-name: shimmer;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #222;
}

@keyframes shimmer {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 150%;
  }
}

@media (min-width: 768px) {
  .shine-text {
    animation-duration: 7s;
  }
}

@media (min-width: 1024px) {
  .shine-text {
    animation-duration: 5s;
  }
}

.indicator {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: white;
  border-radius: 50%;
  margin: 10px;
  /* height: 20px;
  width: 20px; */
  color: #a9a9a9;
  box-shadow: 0 0 0 0 rgb(219, 219, 219);
  transform: scale(1);
  animation: pulse 2s infinite;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap {
  width: 100%;
  margin: 0 auto;
}

/* -------------------------------------
 * Bar container
 * ------------------------------------- */
.progress-radial {
  position: relative;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* -------------------------------------
 * Optional centered circle w/text
 * ------------------------------------- */
.progress-radial .overlay {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: #fffde8;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* -------------------------------------
 * Mixin for progress-% class
 * ------------------------------------- */
.progress-0 {
  background-image: linear-gradient(90deg, #cecece  50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(90deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-5 {
  background-image: linear-gradient(90deg, #cecece  50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(108deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-10 {
  background-image: linear-gradient(90deg, #cecece  50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(126deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-15 {
  background-image: linear-gradient(90deg, #cecece  50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(144deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-20 {
  background-image: linear-gradient(90deg, #cecece  50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(162deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-25 {
  background-image: linear-gradient(90deg, #cecece  50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(180deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-30 {
  background-image: linear-gradient(90deg, #cecece  50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(198deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-35 {
  background-image: linear-gradient(90deg, #cecece  50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(216deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-40 {
  background-image: linear-gradient(90deg, #cecece  50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(234deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-45 {
  background-image: linear-gradient(90deg, #cecece  50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(252deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-50 {
  background-image: linear-gradient(-90deg, #f97686 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-55 {
  background-image: linear-gradient(-72deg, #f97686 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-60 {
  background-image: linear-gradient(-54deg, #f97686 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-65 {
  background-image: linear-gradient(-36deg, #f97686 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-70 {
  background-image: linear-gradient(-18deg, #f97686 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-75 {
  background-image: linear-gradient(0deg, #f97686 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-80 {
  background-image: linear-gradient(18deg, #f97686 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-85 {
  background-image: linear-gradient(36deg, #f97686 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-90 {
  background-image: linear-gradient(54deg, #f97686 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-95 {
  background-image: linear-gradient(72deg, #f97686 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #f97686 50%, #cecece  50%, #cecece );
}

.progress-100 {
  background-image: linear-gradient(90deg, #f97686 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #f97686 50%, #cecece  50%, #cecece );
}


@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(128, 128, 128, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(125, 125, 125, 0);
  }
}

.scroll-down {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  color: rgb(142, 142, 142);
  font-weight: 400;
  text-transform: uppercase;
  font-size: 16px;
  overflow: visible;
}

.scroll-down .arrow {
  position: relative;
  top: -4px;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  color: rgb(142, 142, 142);
  background-image: url(../images/down.svg);
  background-size: contain;
  animation: jumpInfinite 1.5s infinite;
}

@keyframes jumpInfinite {
  0% {
    top: 0;
  }
  50% {
    top: 20px;
  }
  100% {
    top: 0;
  }
}
